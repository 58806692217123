body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overscroll-behavior-y: contain;
}
.btn__social--list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
  }
  
  .btn__social {
    width: 150px;
  }
  
  .btn--github {
    background-color: #1b1c1d;
  }
  
  .btn--github:hover {
    background-color: #232425;
  }
  
  .btn--twitter {
    background-color: #55acee;
  }
  
  .btn--twitter:hover {
    background-color: rgb(67, 158, 228);
  }
  
  .btn--facebook {
    background-color: #3b5998;
  }
  
  .btn--facebook:hover {
    background-color: rgb(45, 77, 146);
  }
.btn__profiles--list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
  }
  
  .container__profile {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin: 10px;
  }
  
  .container__profile--photo {
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }
  
  .container__profile--btn {
    font-size: 0.65rem;
    width: 80px;
    height: 28px;
    background-color: transparent;
    border: 1px solid red;
    color: red;
  }
  
  .container__profile--btn:hover {
    background-color: red;
    color: white;
  }
